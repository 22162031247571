import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Privacy from 'src/components/Legal/Privacy'
import SEO from 'src/components/shared/SEO'

const PrivacyPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Privacy Policy" location={location} />

    <Layout location={location}>
      <Privacy />
    </Layout>
  </>
)

export default PrivacyPage
