import React from 'react'
import styled from 'styled-components'

import { Headline } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  height: fit-content;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.mobile`
    padding: 150px 10px 80px 10px;
  `}
  ${media.tablet`
    padding: 220px 50px 100px 50px;
  `}
  ${media.desktop`
    padding: 250px 40px 120px 40px;
  `}
  & ul {
    padding: 0 0 0 16px;
    margin-top: -12px;
  }
  & a {
    color: ${colors.orange};
  }
  & a:hover {
    font-weight: 900;
  }
`

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  ${media.mobile`
    margin-top: 20px;
    max-width: 95%;
  `}
  ${media.tablet`
    margin-top: 50px;
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 1000px;
  `}
`

interface LegalViewProps {
  title: string
}

const LegalView: React.FC<LegalViewProps> = ({ title, children }) => (
  <Section>
    <Headline>{title}</Headline>
    <Container>{children}</Container>
  </Section>
)

export default LegalView
